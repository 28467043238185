import { isBrowser } from "Utils/isBrowser";
export var reformatPrice = function (price) {
    if (price === void 0) { price = 0; }
    if (isBrowser() && window.Intl) {
        var result = new Intl.NumberFormat("ru-RU", {
            style: "currency",
            minimumFractionDigits: 0,
            currency: "RUB",
        }).format(+price);
        return result;
    }
    else {
        return "".concat(price, " \u0440\u0443\u0431.");
    }
};
