var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./GoodsCarouselFallback.scss";
import React, { useLayoutEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useAppSelector } from "Hooks/useAppSelector";
export var GoodsCarouselFallback = function (_a) {
    var category = _a.category, intersectionObserverClassName = _a.intersectionObserverClassName, _b = _a.withTitle, withTitle = _b === void 0 ? true : _b;
    var breakpointMatches = useAppSelector(function (state) { return state.app; }).breakpointMatches;
    var _c = __read(useState("auto"), 2), numberOfSlides = _c[0], setNumberOfSlides = _c[1];
    var _d = __read(useState(16), 2), paddingOfSlides = _d[0], setPaddingOfSlides = _d[1];
    useLayoutEffect(function () {
        breakpointMatches[1440] ? setNumberOfSlides(3) : setNumberOfSlides(4);
        breakpointMatches[960] && setNumberOfSlides("auto");
        setPaddingOfSlides(breakpointMatches[600] ? 8 : 16);
    }, [breakpointMatches]);
    return (React.createElement("div", { className: "goodsCarousel_skeleton" +
            (intersectionObserverClassName ? " ".concat(intersectionObserverClassName) : "") +
            (category ? " goodsCarousel_".concat(category) : "") },
        withTitle && React.createElement("div", { className: 'goodsCarousel_skeleton__title skeletonLoading' }),
        React.createElement(Swiper, { className: 'goodsCarousel__track_skeleton', spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides }, [1, 2, 3, 4].map(function (item) { return (React.createElement(SwiperSlide, { key: item },
            React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton' },
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__preview skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__brand skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__name skeletonLoading' }),
                React.createElement("div", { className: 'goodsCarousel__track__slide_skeleton__price skeletonLoading' })))); }))));
};
