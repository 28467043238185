var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CustomCollapse.scss";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
var CollapseContext = createContext(null);
var useCollapseContext = function () {
    var currentContext = useContext(CollapseContext);
    if (!currentContext)
        throw new Error("No context found for Collapse");
    return currentContext;
};
export var CustomCollapse = function (_a) {
    var _b = _a.accordion, accordion = _b === void 0 ? false : _b, _c = _a.activeKeys, activeKeys = _c === void 0 ? undefined : _c, children = _a.children, className = _a.className, _d = _a.expandIconPosition, expandIconPosition = _d === void 0 ? "end" : _d, _e = _a.expandIcon, expandIcon = _e === void 0 ? function () { return undefined; } : _e;
    var _f = __read(useState(activeKeys), 2), activePanels = _f[0], setActivePanels = _f[1];
    var toggleActivePanels = function (key) { return setActivePanels(key); };
    useEffect(function () {
        setActivePanels(activeKeys);
    }, [activeKeys]);
    return (React.createElement(CollapseContext.Provider, { value: { expandIconFn: expandIcon, expandIconPosition: expandIconPosition, activeKeys: activePanels, accordion: accordion, toggleActivePanels: toggleActivePanels } },
        React.createElement("div", { className: "customCollapse" + (className ? " ".concat(className) : "") }, children)));
};
var Panel = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.collapsible, collapsible = _b === void 0 ? true : _b, _c = _a.forceRender, forceRender = _c === void 0 ? false : _c, header = _a.header, onChange = _a.onChange, panelKey = _a.panelKey;
    var _d = useCollapseContext(), activeKeys = _d.activeKeys, accordion = _d.accordion, expandIconFn = _d.expandIconFn, expandIconPosition = _d.expandIconPosition, toggleActivePanels = _d.toggleActivePanels;
    var _e = __read(useState(!forceRender), 2), isFirstRender = _e[0], setIsFirstRender = _e[1];
    var _f = __read(useState(false), 2), isActive = _f[0], setIsActive = _f[1];
    useEffect(function () {
        !isFirstRender && onChange && onChange(isActive);
    }, [isActive, isFirstRender]);
    var headerRef = useRef(null);
    var contentWrapperRef = useRef(null);
    useEffect(function () {
        var isIdMatched = Array.isArray(activeKeys) ? !!activeKeys.find(function (el) { return el == panelKey; }) : activeKeys == panelKey;
        if (isIdMatched && isFirstRender) {
            setIsFirstRender(false);
        }
        else if (isIdMatched) {
            openHandler();
        }
        else if (!isIdMatched && !isFirstRender) {
            closeHandler();
        }
    }, [activeKeys]);
    useEffect(function () {
        !isFirstRender && !forceRender && openHandler();
    }, [isFirstRender]);
    var expandIcon = useMemo(function () {
        return collapsible ? expandIconFn({ isActive: isActive, header: header, panelKey: panelKey, collapsible: collapsible, className: className }) : null;
    }, [isActive, collapsible]);
    var collapseHandler = function (e) {
        if (headerRef.current.contains(e.target)) {
            if (!collapsible || (activeKeys && activeKeys.toString().length && !accordion))
                return;
            if (isFirstRender) {
                setIsFirstRender(false);
                return;
            }
            isActive ? closeHandler() : openHandler();
        }
    };
    var closeHandler = function () {
        accordion && activeKeys === panelKey && toggleActivePanels("");
        setIsActive(false);
    };
    var openHandler = function () {
        accordion && toggleActivePanels(panelKey);
        setIsActive(true);
    };
    return (React.createElement("div", { className: "customCollapse__panel" + (className ? " ".concat(className) : "") },
        React.createElement("div", { className: 'customCollapse__panel__header', ref: headerRef, onClick: collapseHandler },
            React.createElement("div", { className: 'customCollapse__panel__header__text' }, header),
            expandIcon && (React.createElement("div", { className: "customCollapse__panel__header__icon customCollapse__panel__header__icon_".concat(expandIconPosition) }, expandIcon))),
        !isFirstRender ? (React.createElement("div", { className: "customCollapse__panel__contentWrapper" + (isActive ? " customCollapse__panel__contentWrapper_active" : ""), ref: contentWrapperRef },
            React.createElement("div", { className: 'customCollapse__panel__content' }, children))) : null));
};
CustomCollapse.Panel = Panel;
