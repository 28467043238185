var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import "./Loader.scss";
import React from "react";
export var Loader = function (_a) {
    var className = _a.className, style = _a.style, height = _a.height, isPageLoader = _a.isPageLoader;
    return (React.createElement("div", { className: "loader__wrapper" + (className ? " " + className : "") + (isPageLoader ? " loader__wrapper_page" : ""), style: __assign(__assign({}, style), { height: height }) },
        React.createElement("svg", { className: 'loader__content', xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 24 24', width: '24', height: '24' },
            React.createElement("path", { d: 'M18.4 5.6 17 7.1a7 7 0 1 0 2 4.9h2a9 9 0 1 1-2.6-6.4z' }))));
};
