export var declOfNum = function (number, wordsArr) {
    var absoluteNumber = Math.abs(number) % 100;
    var modulo = absoluteNumber % 10;
    if (absoluteNumber > 10 && absoluteNumber < 20) {
        return wordsArr[2];
    }
    if (modulo > 1 && modulo < 5) {
        return wordsArr[1];
    }
    if (modulo == 1) {
        return wordsArr[0];
    }
    return wordsArr[2];
};
