import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import "./AdaptivePopup.scss";
import React from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CustomModal } from "Components/CustomModal/CustomModal";
export var AdaptivePopup = function (_a) {
    var children = _a.children, onClose = _a.onClose, contentClassName = _a.contentClassName, _b = _a.drawerClassName, drawerClassName = _b === void 0 ? "" : _b, isPopupShown = _a.isPopupShown, _c = _a.modalClassName, modalClassName = _c === void 0 ? "" : _c, title = _a.title, _d = _a.withDefaultPaddinsModal, withDefaultPaddinsModal = _d === void 0 ? true : _d, _e = _a.withHeader, withHeader = _e === void 0 ? true : _e;
    var breakpointMatches = useAppSelector(function (state) { return state.app; }).breakpointMatches;
    return (React.createElement(React.Fragment, null, breakpointMatches[600] ? (React.createElement(CustomDrawer, { rootClassName: "adaptivePopup__drawer customServiceDrawer_bottom" + (drawerClassName ? " ".concat(drawerClassName) : ""), open: isPopupShown, placement: 'bottom', destroyOnClose: true, onClose: onClose },
        withHeader && (React.createElement("div", { className: 'customServiceDrawer_bottom__title' },
            title && React.createElement("span", { className: 'typography_tec_15_menu' }, title),
            React.createElement(CloseIcon, { className: 'customServiceDrawer_bottom__title__closeIcon', size: 'small', withBackground: true, onClick: onClose }))),
        React.createElement("div", { className: "customServiceDrawer_bottom__content" + (contentClassName ? " ".concat(contentClassName) : "") }, children))) : (React.createElement(CustomModal, { className: "adaptivePopup__modal" + (modalClassName ? " ".concat(modalClassName) : ""), open: isPopupShown, defaultPaddings: withDefaultPaddinsModal, onClose: onClose },
        withHeader && (React.createElement("div", { className: 'customModal__content__header' },
            title && React.createElement("span", { className: 'customModal__content__header__title typography_h1' }, title),
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: onClose }))),
        React.createElement("div", { className: "customModal__content__body" + (contentClassName ? " ".concat(contentClassName) : "") }, children)))));
};
