var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import TagManager from "react-gtm-module";
import { isBrowser } from "Utils/isBrowser";
export var initGTM = function () {
    var isDevMode = process.env.NODE_ENV === "development";
    var isBrowserEnv = isBrowser();
    var tagManagerArgs = {
        gtmId: "GTM-KMNDWNG",
        auth: isDevMode ? "nlzUqI3jMctFMug9zFgbcQ" : "YCFpcSclgeNj052UaLqhsw",
        preview: isDevMode ? "env-100" : "env-2",
    };
    var localData = [];
    if (!isBrowserEnv)
        return localData;
    if (!isDevMode) {
        var role = localStorage.getItem("role");
        if (!role || role === "1") {
            TagManager.initialize(tagManagerArgs);
            localData.push({ originalLocation: location.href });
            var url = sessionStorage.getItem("originalLocation");
            if (url) {
                localData.push({ originalLocation_session: url });
            }
            else {
                sessionStorage.setItem("originalLocation", location.href);
                localData.push({ originalLocation_session: location.href });
            }
        }
    }
    else if (!location.host.startsWith("localhost")) {
        TagManager.initialize(tagManagerArgs);
    }
    var originalData = window.dataLayer;
    if (originalData)
        originalData.push.apply(originalData, __spreadArray([], __read(localData), false));
    return originalData || localData;
};
