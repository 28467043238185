import "./IncreasedDiscountTimer.scss";
import React, { useEffect } from "react";
import { useCountdown } from "Hooks/useCountdown";
import { declOfNum } from "Utils/declOfNum";
export var IncreasedDiscountTimer = function (_a) {
    var className = _a.className, discountPercent = _a.discountPercent, expirationDate = _a.expirationDate, onTimerEnd = _a.onTimerEnd;
    var _b = useCountdown(new Date(expirationDate).getTime()), days = _b.days, hours = _b.hours, minutes = _b.minutes, seconds = _b.seconds;
    var numberOfDays = +days;
    var numberOfHours = +hours;
    useEffect(function () {
        if (+days + +hours + +minutes + +seconds <= 0) {
            onTimerEnd && onTimerEnd();
        }
    }, [days, hours, minutes, seconds]);
    var getTimer = function () {
        if (numberOfDays === 0)
            return "\u043D\u0430 ".concat(hours, ":").concat(minutes, ":").concat(seconds);
        var defaultRow = "\u043D\u0430 ".concat(numberOfDays, " ").concat(declOfNum(numberOfDays, ["день", "дня", "дней"]));
        return numberOfHours === 0
            ? defaultRow
            : defaultRow + " ".concat(numberOfHours, " ").concat(declOfNum(numberOfHours, ["час", "часа", "часов"]));
    };
    return (React.createElement("div", { className: "increasedDiscountTimer" + (className ? " ".concat(className) : "") },
        React.createElement("span", { className: 'increasedDiscountTimer__label typography_h4' }, "+".concat(discountPercent, "%")),
        React.createElement("div", { className: 'typography_captionTxt' }, getTimer())));
};
