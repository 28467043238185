import { configureStore } from "@reduxjs/toolkit";
import appSlice from "Redux/slices/appSlice";
import cartSlice from "Redux/slices/cartSlice";
import catalogSlice from "Redux/slices/catalogSlice";
import postsSlice from "Redux/slices/postsSlice";
import userInfoSlice from "Redux/slices/userInfoSlice";
import { isBrowser } from "Utils/isBrowser";
var store = configureStore({
    reducer: {
        app: appSlice,
        cart: cartSlice,
        catalog: catalogSlice,
        posts: postsSlice,
        userInfo: userInfoSlice,
    },
    devTools: isBrowser() && window.location.host !== "lsboutique.ru",
});
export default store;
