var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CustomModal.scss";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
export var CustomModal = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.defaultPaddings, defaultPaddings = _b === void 0 ? true : _b, footer = _a.footer, _c = _a.fullscreen, fullscreen = _c === void 0 ? true : _c, header = _a.header, _d = _a.maskClosable, maskClosable = _d === void 0 ? true : _d, onClose = _a.onClose, onRendered = _a.onRendered, open = _a.open;
    var modalBodyRef = useRef(null);
    var modalWrapperRef = useRef(null);
    var modalMaskRef = useRef(null);
    var triggerCoordinates = useRef({ x: 0, y: 0 });
    var scrollWidth = useRef(0);
    var isFirstLoad = useRef(true);
    var isMouseDownInModal = useRef(false);
    var _e = __read(useState(false), 2), isShowed = _e[0], setIsShowed = _e[1];
    useEffect(function () {
        if (open) {
            if (isFirstLoad.current)
                isFirstLoad.current = false;
            var saveCoord_1 = function (e) {
                triggerCoordinates.current = { x: e.clientX, y: e.clientY };
                document.removeEventListener("click", saveCoord_1);
            };
            document.addEventListener("click", saveCoord_1);
            setIsShowed(true);
        }
        else {
            if (!isFirstLoad.current) {
                var modalBody = modalBodyRef.current;
                var modalMask = modalMaskRef.current;
                modalBody.classList.add("customModal__body_closed");
                modalBody.classList.remove("customModal__body_visible");
                modalMask.classList.remove("customModal__mask_blackout");
                setTimeout(function () {
                    setIsShowed(false);
                    triggerCoordinates.current = { x: 0, y: 0 };
                }, 250);
            }
        }
    }, [open]);
    useEffect(function () {
        var _a, _b;
        if (isShowed) {
            if (!scrollWidth.current)
                scrollWidth.current = innerWidth - document.body.clientWidth;
            document.body.style.width = "calc(100% - ".concat(scrollWidth.current, "px)");
            document.body.style.overflowY = "hidden";
            var modalBody_1 = modalBodyRef.current;
            var modalMask_1 = modalMaskRef.current;
            var _c = triggerCoordinates.current, triggerX = _c.x, triggerY = _c.y;
            if (triggerX || triggerY) {
                var transformX = triggerX - (document.documentElement.clientWidth - modalBody_1.offsetWidth) / 2;
                var transformY = document.documentElement.clientHeight > modalBody_1.offsetHeight
                    ? triggerY - (document.documentElement.clientHeight - modalBody_1.offsetHeight) / 2
                    : triggerY;
                modalBody_1.style.transformOrigin = "".concat(transformX, "px ").concat(transformY, "px");
            }
            setTimeout(function () {
                modalBody_1.classList.add("customModal__body_visible");
                modalMask_1.classList.add("customModal__mask_blackout");
            }, 0);
            var isHaveFocus = (_a = modalBodyRef.current) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement);
            !isHaveFocus && ((_b = modalBodyRef.current) === null || _b === void 0 ? void 0 : _b.focus());
        }
        return function () {
            var modalsCount = document.body.querySelectorAll(".customModal").length;
            var drawersCount = document.body.querySelectorAll(".customDrawer").length;
            if (!modalsCount && !drawersCount) {
                document.body.style.overflowY = "";
                document.body.style.width = "";
            }
        };
    }, [isShowed]);
    useEffect(function () {
        onRendered && onRendered(isShowed);
    }, [isShowed]);
    var modalHandler = useCallback(function (e) {
        var target = e.target;
        if (!target.closest(".customModal__contentWrapper") &&
            modalWrapperRef.current.contains(target) &&
            !isMouseDownInModal.current &&
            maskClosable &&
            onClose) {
            onClose();
        }
        isMouseDownInModal.current = false;
    }, [onClose]);
    var escapeHandler = useCallback(function (e) {
        var isCurrentModal = e.currentTarget.contains(document.activeElement);
        e.code === "Escape" && isCurrentModal && onClose && onClose();
    }, [onClose]);
    var mouseDownHandler = useRef(function (e) {
        var target = e.target;
        if (target.closest(".customModal__contentWrapper"))
            isMouseDownInModal.current = true;
    });
    return isShowed
        ? createPortal(React.createElement("div", { className: 'customModal' },
            React.createElement("div", { className: 'customModal__mask', ref: modalMaskRef }),
            React.createElement("div", { className: 'customModal__wrapper', onClick: modalHandler, onKeyDown: escapeHandler, ref: modalWrapperRef },
                React.createElement("div", { className: "customModal__body" +
                        (fullscreen ? " customModal__body_fullscrean" : "") +
                        (className ? " " + className : ""), "aria-modal": true, "aria-label": 'Modal content', role: 'dialog', onMouseDown: mouseDownHandler.current, ref: modalBodyRef, tabIndex: 0 },
                    React.createElement("div", { className: "customModal__contentWrapper" +
                            (defaultPaddings ? " customModal__contentWrapper_defaultPaddings" : "") },
                        React.createElement("div", { className: 'customModal__content' },
                            header && (React.createElement("div", { className: 'customModal__content__header' },
                                React.createElement("span", { className: 'customModal__content__header__title typography_h1' }, header),
                                React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: onClose }))),
                            children),
                        footer && React.createElement("div", { className: 'customModal__footer' }, footer))))), document.body)
        : null;
};
