export var canUseWebP = function () {
    var _a;
    if (typeof window === "undefined")
        return false;
    var elem = document.createElement("canvas");
    if (!!(elem.getContext && elem.getContext("2d")) && elem.toDataURL("image/webp").indexOf("data:image/webp") === 0) {
        return true;
    }
    var match = navigator.userAgent.match(/OS X\s?(?<os>\d+)?.+ Version\/(?<v>\d+\.\d+)/);
    if ((match === null || match === void 0 ? void 0 : match.groups) && +((_a = match.groups) === null || _a === void 0 ? void 0 : _a.v) >= 14) {
        return true;
    }
    return false;
};
