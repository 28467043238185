export var checkFlexGap = function () {
    var _a;
    var flex = document.createElement("div");
    flex.style.display = "flex";
    flex.style.flexDirection = "column";
    flex.style.rowGap = "1px";
    flex.appendChild(document.createElement("div"));
    flex.appendChild(document.createElement("div"));
    document.body.appendChild(flex);
    var isSupported = flex.scrollHeight === 1;
    (_a = flex === null || flex === void 0 ? void 0 : flex.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(flex);
    return isSupported;
};
