import "./MobileMenu.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { MapPointIcon } from "Svg/MapPointIcon";
import { PhoneIcon } from "Svg/PhoneIcon";
import { CustomDrawer } from "Components/CustomDrawer/CustomDrawer";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { GenderSelection } from "Components/GenderSelection/GenderSelection";
export var MobileMenu = function (_a) {
    var menuList = _a.menuList, closeMenu = _a.closeMenu, showMobileMenu = _a.showMobileMenu, openCitySelection = _a.openCitySelection;
    var city = useAppSelector(function (state) { return state.app; }).city;
    var activeCategory = useAppSelector(function (state) { return state.catalog; }).activeCategory;
    var isCurrentCategory = function (category) {
        var decodedCategory = "";
        try {
            decodedCategory = decodeURI(activeCategory);
        }
        catch (e) { }
        return decodedCategory === category;
    };
    return (React.createElement(CustomDrawer, { rootClassName: 'mobileMenu__drawer customServiceDrawer_side', placement: 'left', destroyOnClose: true, open: showMobileMenu, onClose: closeMenu, title: React.createElement("div", { className: 'customServiceDrawer_side__header' },
            React.createElement("span", { className: 'customServiceDrawer_side__header__title typography_tec_13_semiBold' }, "\u041A\u0410\u0422\u0410\u041B\u041E\u0413"),
            React.createElement(CloseIcon, { className: 'customServiceDrawer_side__header__closeIcon', onClick: closeMenu })) },
        React.createElement("div", { id: 'mobileMenuWrapper', className: 'mobileMenu__wrapper' },
            React.createElement(GenderSelection, null),
            React.createElement("nav", { className: 'mobileMenu__navigation' }, menuList.map(function (item) { return (React.createElement(NavLink, { className: "typography_tec_15_menu mobileMenu__navigation__item" +
                    (isCurrentCategory(item.slug) ? " mobileMenu__navigation__item_selected" : ""), to: item.url, key: item.name, "data-is-highlight": item.highlight, onClick: closeMenu }, item.name)); })),
            React.createElement("div", { className: 'mobileMenu__city', onClick: openCitySelection },
                React.createElement(MapPointIcon, null),
                React.createElement("span", { className: 'typography_tec_16_medium' }, city === null || city === void 0 ? void 0 : city.city_name)),
            React.createElement("div", { className: 'mobileMenu__contacts' },
                React.createElement(PhoneIcon, null),
                React.createElement("div", { className: 'mobileMenu__contacts__info' },
                    React.createElement("a", { className: 'typography_tec_16_medium', href: 'tel:+74953758934' }, "+7 (495) 375-89-34"),
                    React.createElement("span", { className: 'typography_tec_14_body' }, "\u0415\u0436\u0435\u0434\u043D\u0435\u0432\u043D\u043E \u0441 09:00 \u0434\u043E 22:00"))))));
};
