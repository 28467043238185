var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import "./SocialIcon.scss";
import React from "react";
import { OKIcon } from "Svg/OKIcon";
import { TelegramIcon } from "Svg/TelegramIcon";
import { VKIcon } from "Svg/VKIcon";
export var SocialIcon = function (_a) {
    var type = _a.type, onClick = _a.onClick, href = _a.href, props = __rest(_a, ["type", "onClick", "href"]);
    var isLink = (href === null || href === void 0 ? void 0 : href.length) && !onClick;
    var Icon = type === "ok" ? (React.createElement(OKIcon, { className: 'socialIcon_ok' })) : type === "vk" ? (React.createElement(VKIcon, { className: 'socialIcon_vk' })) : (React.createElement(TelegramIcon, { className: 'socialIcon_tg' }));
    return isLink ? (React.createElement("a", __assign({ className: 'socialIcon customBtn customBtn_icon customBtn_iconLarge', href: href }, props), Icon)) : (React.createElement("button", __assign({ className: 'socialIcon customBtn customBtn_icon customBtn_iconLarge', onClick: function () { return onClick && onClick(type); } }, props), Icon));
};
