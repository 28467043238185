var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./DesktopCatalogFiltersSkeleton.scss";
import React from "react";
export var DesktopCatalogFiltersSkeleton = function () {
    var filters = __spreadArray([], __read(Array(12)), false).map(function (_, i) { return i + 1; });
    return (React.createElement("div", { className: 'desktopFiltersSkeleton' },
        React.createElement("div", { className: 'desktopFiltersSkeleton' },
            React.createElement("div", { className: 'desktopFiltersSkeleton__item' },
                React.createElement("div", { className: 'desktopFiltersSkeleton__item__title skeletonLoading' }),
                React.createElement("div", { className: 'desktopFiltersSkeleton__item__list' }, filters.map(function (item) { return (React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter', key: item },
                    React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter__checkbox skeletonLoading' }),
                    React.createElement("div", { className: 'desktopFiltersSkeleton__item__list__filter__text skeletonLoading' }))); }))),
            [1, 2].map(function (item) { return (React.createElement("div", { className: 'desktopFiltersSkeleton__item', key: item },
                React.createElement("div", { className: 'desktopFiltersSkeleton__item__title skeletonLoading' }))); }))));
};
