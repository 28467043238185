var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./PromoAction.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeAuthModalVisibility } from "Redux/slices/appSlice";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { AdaptivePopup } from "Components/AdaptivePopup/AdaptivePopup";
export var PromoAction = function (_a) {
    var promoActionData = _a.promoActionData;
    var _b = useAppSelector(function (state) { return state.app; }), breakpointMatches = _b.breakpointMatches, deviceInfo = _b.deviceInfo, isAuthModalShown = _b.isAuthModalShown;
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var location = useLocation();
    var _c = __read(useState(function () {
        var activePromosInSession = sessionStorage.getItem("promoActions");
        if (activePromosInSession) {
            return JSON.parse(activePromosInSession);
        }
        return [];
    }), 2), promosInSession = _c[0], setPromosInSession = _c[1];
    var _d = __read(useState(false), 2), isPromoShown = _d[0], setIsPromoShown = _d[1];
    var _e = __read(useState(0), 2), timeoutId = _e[0], setTtimeoutId = _e[1];
    useEffect(function () {
        var currentPromoInSession = promosInSession.find(function (promo) { return promo.name === promoActionData.name; });
        if (!currentPromoInSession || !currentPromoInSession.startTimerDone) {
            var updatedPromosForSession_1 = promosInSession.filter(function (promo) { return promo.name !== promoActionData.name; });
            updatedPromosForSession_1.push({ startTimerDone: false, name: promoActionData.name, repeatTimerDone: false });
            setPromosInSession(updatedPromosForSession_1);
            sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSession_1));
            var timoutId = setTimeout(function () {
                setIsPromoShown(true);
                var updatedPromosForSessionWithFirstTimer = updatedPromosForSession_1.map(function (promo) {
                    if (promo.name === promoActionData.name) {
                        return __assign(__assign({}, promo), { startTimerDone: true });
                    }
                    return promo;
                });
                setPromosInSession(updatedPromosForSessionWithFirstTimer);
                sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSessionWithFirstTimer));
            }, promoActionData.timer);
            setTtimeoutId(timoutId);
        }
    }, []);
    useEffect(function () {
        var currentPromoInSession = promosInSession.find(function (promo) { return promo.name === promoActionData.name; });
        if (!isPromoShown &&
            currentPromoInSession &&
            currentPromoInSession.startTimerDone &&
            !(currentPromoInSession === null || currentPromoInSession === void 0 ? void 0 : currentPromoInSession.repeatTimerDone)) {
            var timoutId = setTimeout(function () {
                setIsPromoShown(true);
                var updatedPromosForSessionWithFirstTimer = __spreadArray([], __read(promosInSession), false).map(function (promo) {
                    if (promo.name === promoActionData.name) {
                        return __assign(__assign({}, promo), { repeatTimerDone: true });
                    }
                    return promo;
                });
                setPromosInSession(updatedPromosForSessionWithFirstTimer);
                sessionStorage.setItem("promoActions", JSON.stringify(updatedPromosForSessionWithFirstTimer));
            }, promoActionData.repeatTimer);
            setTtimeoutId(timoutId);
        }
    }, [isPromoShown, promosInSession]);
    useEffect(function () {
        if (isAuthModalShown || location.pathname === (promoActionData === null || promoActionData === void 0 ? void 0 : promoActionData.url) || location.pathname.startsWith("/checkout/")) {
            clearTimeout(timeoutId);
        }
    }, [timeoutId, user, isAuthModalShown, location.pathname]);
    var closeHandler = function () {
        setIsPromoShown(false);
    };
    var _f = __read(useState(true), 2), isImgLoading = _f[0], setImgLoading = _f[1];
    useEffect(function () {
        setImgLoading(true);
    }, [breakpointMatches[600]]);
    var isFirstOrderDiscount = useRef(promoActionData.name === "firstOrderDiscount");
    return (React.createElement(AdaptivePopup, { onClose: closeHandler, drawerClassName: 'promoAction__drawer', isPopupShown: isPromoShown, modalClassName: 'promoAction__modal', withDefaultPaddinsModal: false, withHeader: false },
        React.createElement("div", { className: "promoAction__".concat(breakpointMatches[600] ? "drawer" : "modal", "__content") +
                (isImgLoading ? " skeletonLoading" : ""), style: {
                maxHeight: breakpointMatches[600]
                    ? deviceInfo.deviceType === "mobile"
                        ? document.documentElement.clientHeight - 56 + "px"
                        : "calc(100vh - 56px)"
                    : "",
            } },
            React.createElement(CloseIcon, { className: (breakpointMatches[600] ? "promoAction__closeIcon" : "customModal__content__header__closeIcon") +
                    (isImgLoading ? " promoAction__ui_hidden" : ""), onClick: closeHandler }),
            React.createElement("img", { className: "promoAction__".concat(breakpointMatches[600] ? "drawer" : "modal", "__content__banner"), src: promoActionData[breakpointMatches[600] ? "mobileImg" : "desktopImg"], alt: 'promo banner', onLoad: function () { return setImgLoading(false); } }),
            (isFirstOrderDiscount.current || promoActionData.url) && (React.createElement(ActionControl, { className: isImgLoading ? "promoAction__ui_hidden" : "", closeHandler: closeHandler, isAuthAction: !!isFirstOrderDiscount.current, isDrawer: !!breakpointMatches[600], url: promoActionData.url })))));
};
var ActionControl = function (_a) {
    var className = _a.className, isAuthAction = _a.isAuthAction, isDrawer = _a.isDrawer, closeHandler = _a.closeHandler, url = _a.url;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var actionHandler = function () {
        closeHandler();
        if (isAuthAction) {
            sessionStorage.setItem("fromDiscountPromo", "true");
            dispatch(changeAuthModalVisibility(true));
        }
        else if (url) {
            navigate(url);
        }
    };
    return (React.createElement("div", { className: "promoAction__actionControl-wrapper" + (className ? " ".concat(className) : "") },
        React.createElement("button", { className: "promoAction__actionControl customBtn customBtn_contained customBtn".concat(isDrawer ? "" : "_large"), onClick: actionHandler }, isAuthAction ? "Зарегистрироваться" : "Перейти в каталог")));
};
