export var reformatPhone = function (userPhone) {
    var phone = userPhone;
    if (phone === null || phone === void 0 ? void 0 : phone.startsWith("+")) {
        phone = phone.substring(1);
    }
    if (phone) {
        switch (phone.length) {
            case 10:
                return addWhitespaces(phone);
            case 11:
                return addWhitespaces(phone.substr(-10), phone);
            case 13:
                return phone.replace(/[^\d]/g, "");
            default:
                return phone;
        }
    }
    else {
        return "";
    }
};
var addWhitespaces = function (phoneBody, phone) {
    return ((phone ? phone.slice(0, phone.length - 10) + " " : "") +
        phoneBody.substring(0, 3) +
        " " +
        phoneBody.substring(3, 6) +
        " " +
        phoneBody.substring(6, 8) +
        " " +
        phoneBody.substring(8));
};
