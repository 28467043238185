var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./ErrorBoundary.scss";
import React from "react";
import store from "Redux/store";
import { fetchApi } from "Utils/fetchApi";
var ErrorBoundary = (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.state = { isLayoutShown: false, chunkError: false };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        var chunkErrorArr = error.message
            ? error.message.match(/Loading chunk/gi) || error.message.match(/Loading CSS chunk/gi)
            : [];
        if (chunkErrorArr && chunkErrorArr.length > 0) {
            if (window["chunkErrorReload"])
                return { isLayoutShown: false, chunkError: true };
            var lastErrorTime = parseInt(localStorage.getItem("chunkErrorTime") || "0");
            var currentTime = new Date().getTime();
            if (currentTime - lastErrorTime > 60000) {
                window["chunkErrorReload"] = true;
                localStorage.setItem("chunkErrorTime", "" + currentTime);
                window.location.reload();
                return { isLayoutShown: false, chunkError: true };
            }
        }
        return { isLayoutShown: true, chunkError: chunkErrorArr && chunkErrorArr.length > 0 };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, trace) {
        var _a;
        if (location.origin === "https://lsboutique.ru" && this.state.isLayoutShown && !this.state.chunkError) {
            fetchApi({
                method: "POST",
                url: "/log-error",
                body: {
                    errorText: error.toString(),
                    url: location.href,
                    navigantor: {
                        appVersion: navigator === null || navigator === void 0 ? void 0 : navigator.appVersion,
                        platform: navigator === null || navigator === void 0 ? void 0 : navigator.platform,
                        userAgent: navigator === null || navigator === void 0 ? void 0 : navigator.userAgent,
                        userAgentData: navigator === null || navigator === void 0 ? void 0 : navigator.userAgentData,
                        vendor: navigator === null || navigator === void 0 ? void 0 : navigator.vendor,
                    },
                    localStorage: localStorage,
                    reduxState: store.getState(),
                    PerformanceEntry: ((_a = window.performance) === null || _a === void 0 ? void 0 : _a.getEntries())
                        ? JSON.stringify(performance.getEntries())
                        : "not supported",
                    trace: JSON.stringify(trace),
                },
            });
        }
    };
    ErrorBoundary.prototype.render = function () {
        var _a = this.state, chunkError = _a.chunkError, isLayoutShown = _a.isLayoutShown;
        if (isLayoutShown) {
            return (React.createElement("div", { className: 'errorBoundary__wrapper' },
                React.createElement("img", { src: 'https://cdn.lsboutique.ru/images/error.png', alt: 'Sorry, cliet-server error' }),
                React.createElement("p", null, chunkError ? "Упс! Похоже интернет соединение прервалось." : "Упс! Похоже у нас что-то поломалось."),
                React.createElement("span", null, chunkError ? "Попробуйте перезагрузить страницу." : "Попробуйте вернуться на нашу главную страницу."),
                !chunkError && (React.createElement("a", { href: '/' },
                    React.createElement("button", { className: 'customBtn customBtn_contained' }, "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E")))));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
