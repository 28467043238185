var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export var ChevronIcon_16 = function (_a) {
    var className = _a.className, style = _a.style;
    return (React.createElement("svg", { className: className, style: __assign({}, style), xmlns: 'http://www.w3.org/2000/svg', width: '16', height: '16', fill: 'none', viewBox: '0 0 16 16' },
        React.createElement("path", { fill: 'var(--grey-800)', fillRule: 'evenodd', d: 'M8.18 7.76 5.53 5.1a.75.75 0 0 1 1.06-1.06l3.18 3.18a.75.75 0 0 1 0 1.06L6.6 11.47a.75.75 0 1 1-1.06-1.06l2.65-2.65Z', clipRule: 'evenodd' })));
};
