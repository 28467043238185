import "./PopularCategories.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { saveLocalFilters } from "Redux/slices/catalogSlice";
export var PopularCategories = function (_a) {
    var className = _a.className, isLoading = _a.isLoading, popularCategoriesData = _a.popularCategoriesData;
    var dispatch = useAppDispatch();
    var clickHandler = function (category) {
        localStorage.setItem("catalogUrl", window.location.origin + category.url);
        dispatch(saveLocalFilters(category.filters));
    };
    return (React.createElement("div", { className: "popularCategories" + (className ? " ".concat(className) : "") },
        React.createElement("p", { className: 'popularCategories__title typography_title' }, "\u041F\u043E\u043F\u0443\u043B\u044F\u0440\u043D\u044B\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
        React.createElement("div", { className: "popularCategories__content" + (isLoading ? " popularCategories__content_skeletonLayout" : "") }, isLoading
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(function (skeleton) { return (React.createElement("div", { key: skeleton, className: 'popularCategories__item_skeleton skeletonLoading' })); })
            : popularCategoriesData.map(function (category) { return (React.createElement(NavLink, { className: 'popularCategories__item skeletonLoading', key: category.id, to: category.url, "aria-label": "\u0421atalog category - ".concat(category.name), onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onClick: function () { return clickHandler(category); } },
                React.createElement("img", { src: category.image, alt: "Preview for ".concat(category.name, " category"), loading: 'lazy' }))); }))));
};
