var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./PseudoSearchInput.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { SearchIcon } from "Svg/SearchIcon";
import { isBrowser } from "Utils/isBrowser";
export var PseudoSearchInput = function (_a) {
    var className = _a.className;
    var isFriendlyIp = useAppSelector(function (state) { return state.app; }).isFriendlyIp;
    var _b = __read(useState(isBrowser() && !!(window === null || window === void 0 ? void 0 : window.digiCustom) ? false : true), 2), isAnyQueryScriptLoad = _b[0], setIsAnyQueryScriptLoad = _b[1];
    useEffect(function () {
        isAnyQueryScriptLoad &&
            setTimeout(function () {
                var script = document.createElement("script");
                script.src = "https://cdn.diginetica.net/2648/client.js";
                script.async = true;
                script.onload = function () {
                    setIsAnyQueryScriptLoad(false);
                };
                document.body.append(script);
            }, 3000);
    }, []);
    return (React.createElement("div", { className: "pseudoSearchInput AnyQuery__control" +
            (isAnyQueryScriptLoad ? " pseudoSearchInput_disabled" : "") +
            (className ? " ".concat(className) : ""), "data-is_friendly_ip": isFriendlyIp },
        React.createElement("p", { className: 'pseudoSearchInput__placeholder typography_tec_buttonM' }, isAnyQueryScriptLoad ? "Загрузка.." : "Поиск"),
        React.createElement("div", { className: 'pseudoSearchInput__searchBtn' },
            React.createElement(SearchIcon, { className: 'pseudoSearchInput__searchBtn__icon' }))));
};
