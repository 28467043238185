import { isBrowser } from "Utils/isBrowser";
export default {
    getItem: function (key) {
        return isBrowser() ? window.localStorage.getItem(key) : null;
    },
    setItem: function (key, value) {
        isBrowser() && window.localStorage.setItem(key, value);
    },
    removeItem: function (key) {
        isBrowser() && window.localStorage.removeItem(key);
    },
};
