var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import "./OptionalCarousel.scss";
import React, { useEffect, useState } from "react";
import { fetchApi } from "Utils/fetchApi";
import { IntersectionObserverWrapper } from "Components/IntersectionObserverWrapper/IntersectionObserverWrapper";
import { GoodsCarousel } from "Components/GoodsCarousel/GoodsCarousel";
export var OptionalCarousel = function (_a) {
    var category = _a.category, className = _a.className, _b = _a.deps, deps = _b === void 0 ? [] : _b, url = _a.url;
    var _c = __read(useState(false), 2), isLoading = _c[0], setIsLoading = _c[1];
    var _d = __read(useState(false), 2), isCarouselShown = _d[0], setIsCarouselShown = _d[1];
    var _e = __read(useState([]), 2), goodsData = _e[0], setGoodsData = _e[1];
    var _f = __read(useState(""), 2), title = _f[0], setTitle = _f[1];
    useEffect(function () {
        setIsLoading(true);
        isCarouselShown &&
            fetchApi({ method: "GET", url: url }).then(function (_a) {
                var data = _a.data;
                setGoodsData(data.products || data.data);
                setTitle(data.title);
                setIsLoading(false);
            });
    }, __spreadArray(__spreadArray([], __read(deps), false), [isCarouselShown], false));
    if (!isLoading && goodsData.length === 0) {
        return null;
    }
    return (React.createElement("div", { className: "optionalCarousel" + (className ? " ".concat(className) : "") },
        isLoading ? (React.createElement("div", { className: 'optionalCarousel__title_skeleton skeletonLoading' })) : (title && React.createElement("p", { className: 'optionalCarousel__title typography_title' }, title)),
        React.createElement("div", { className: 'optionalCarousel__slider' },
            React.createElement(IntersectionObserverWrapper, { querySelector: ".goodsCarousel_".concat(category), observerCallback: function () { return setIsCarouselShown(true); }, children: React.createElement(GoodsCarousel, { categoryOfGoodsData: goodsData, category: category, isLoading: isLoading }) }))));
};
