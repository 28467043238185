var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./NotFound404.scss";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "Hooks/useAppSelector";
import { fetchApi } from "Utils/fetchApi";
import { metaTitle_end } from "Static/texts";
import { PopularCategories } from "Components/PopularCategories/PopularCategories";
import { PopularBrands } from "Components/PopularBrands/PopularBrands";
import { OptionalCarousel } from "Components/OptionalCarousel/OptionalCarousel";
export var NotFound404 = function () {
    var genderData = useAppSelector(function (state) { return state.app; }).genderData;
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    useEffect(function () {
        document.title = "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430 ".concat(metaTitle_end);
    }, []);
    var _a = __read(useState(true), 2), isPopularCategoriesLoading = _a[0], setIsPopularCategoriesLoading = _a[1];
    var _b = __read(useState([]), 2), popularCategoriesData = _b[0], setPopularCategoriesData = _b[1];
    useEffect(function () {
        getPopularCategories();
    }, [genderData.genderNumber]);
    var getPopularCategories = function () {
        setIsPopularCategoriesLoading(true);
        fetchApi({ method: "GET", url: "/main-page/popular-categories?sex=".concat(genderData.genderNumber) }).then(function (_a) {
            var data = _a.data;
            setPopularCategoriesData(data.categories);
            setIsPopularCategoriesLoading(false);
        });
    };
    var _c = __read(useState(true), 2), isBrandsDataLoading = _c[0], setIsBrandsDataLoading = _c[1];
    var _d = __read(useState([]), 2), brandsData = _d[0], setBrandsData = _d[1];
    useEffect(function () {
        getBrandsData();
    }, [genderData.genderNumber]);
    var getBrandsData = function () {
        setIsBrandsDataLoading(true);
        fetchApi({
            method: "GET",
            url: "/main-page/brands/?sex=".concat(genderData.genderNumber, "&blocks=brands&imageSize=220"),
        }).then(function (_a) {
            var data = _a.data;
            setBrandsData(data.data);
            setIsBrandsDataLoading(false);
        });
    };
    return (React.createElement("div", { className: 'notFound404' },
        React.createElement("div", { className: 'notFound404__sorry' },
            React.createElement("img", { src: 'https://cdn.lsboutique.ru/images/img-404.svg', alt: 'Not found 404' }),
            React.createElement("p", { className: 'notFound404__sorry__title typography_h1' }, "\u0418\u0437\u0432\u0438\u043D\u0438\u0442\u0435, \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430"),
            React.createElement("p", { className: 'notFound404__sorry__subTitle typography_bodyTxt' }, "\u0412\u044B \u043C\u043E\u0436\u0435\u0442\u0435 \u043D\u0430\u0447\u0430\u0442\u044C \u0441\u0432\u043E\u0439 \u0432\u044B\u0431\u043E\u0440 \u0441 \u0433\u043B\u0430\u0432\u043D\u043E\u0439 \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u044B, \u043F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438 \u0438\u043B\u0438 \u0432\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C\u0441\u044F \u043F\u043E\u0438\u0441\u043A\u043E\u043C, \u0435\u0441\u043B\u0438 \u0438\u0449\u0435\u0442\u0435 \u0447\u0442\u043E-\u0442\u043E \u043A\u043E\u043D\u043A\u0440\u0435\u0442\u043D\u043E\u0435."),
            React.createElement("a", { className: 'notFound404__sorry__homeLink customBtn customBtn_contained', href: '/' }, "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u043D\u0430 \u0433\u043B\u0430\u0432\u043D\u0443\u044E")),
        React.createElement(PopularCategories, { className: 'notFound404__popularCategories', isLoading: isPopularCategoriesLoading, popularCategoriesData: popularCategoriesData }),
        React.createElement(PopularBrands, { className: 'notFound404__popularBrands', isLoading: isBrandsDataLoading, popularBrandsData: brandsData }),
        user && (React.createElement(OptionalCarousel, { category: 'viewed', className: 'productPage__content__viewedCarousel', deps: [genderData.genderNumber], url: "/product/viewed?sex=".concat(genderData.genderNumber, "&scheme=2") }))));
};
