var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { catalogFilterNames } from "Static/catalogFilterNames";
import localStorage from "Utils/localStorage";
var emptyFiltersData = {
    brands: [],
    categories: [],
    seasons: [],
    sizes: {
        clothes: [],
        footwear: [],
    },
};
var initialState = {
    activeCategory: "",
    filtersData: emptyFiltersData,
    availableFiltersData: emptyFiltersData,
    brandFilters: JSON.parse(localStorage.getItem("brandFilters") || "[]"),
    categoryFilters: JSON.parse(localStorage.getItem("categoryFilters") || "[]"),
    seasonFilters: JSON.parse(localStorage.getItem("seasonFilters") || "[]"),
    clothesSizeFilters: JSON.parse(localStorage.getItem("clothesSizeFilters") || "[]"),
    footwearSizeFilters: JSON.parse(localStorage.getItem("footwearSizeFilters") || "[]"),
};
export var catalogSlice = createSlice({
    name: "catalog",
    initialState: initialState,
    reducers: {
        changeCatalogCategory: function (state, action) {
            if (state.activeCategory !== action.payload) {
                state.activeCategory = action.payload;
            }
        },
        saveFiltersData: function (state, action) {
            if (JSON.stringify(state.filtersData) !== JSON.stringify(action.payload)) {
                state.filtersData = action.payload;
            }
        },
        saveAvaildableFiltersData: function (state, action) {
            if (JSON.stringify(state.availableFiltersData) !== JSON.stringify(action.payload)) {
                state.availableFiltersData = action.payload;
            }
        },
        saveLocalFilters: function (state, action) {
            for (var key in action.payload) {
                localStorage.setItem(key, JSON.stringify(action.payload[key]));
                state[key] = action.payload[key];
            }
        },
        catalogFiltersHandler: function (state, action) {
            var prevFilters = state[action.payload.categoryId];
            var filterredArr = prevFilters.filter(function (prevValue) { return prevValue !== action.payload.filterId; });
            var updatedFilters = filterredArr.length === prevFilters.length ? __spreadArray(__spreadArray([], __read(prevFilters), false), [action.payload.filterId], false) : filterredArr;
            localStorage.setItem(action.payload.categoryId, JSON.stringify(updatedFilters));
            state[action.payload.categoryId] = updatedFilters;
        },
        clearAllCatalogFilters: function (state) {
            state.filtersData = emptyFiltersData;
            state.availableFiltersData = emptyFiltersData;
            catalogFilterNames.forEach(function (filterName) {
                localStorage.removeItem(filterName);
                state[filterName] = [];
            });
        },
        clearLocalFilters: function (state, action) {
            var filters = action.payload || catalogFilterNames;
            filters.forEach(function (filterName) {
                localStorage.removeItem(filterName);
                state[filterName] = [];
            });
        },
    },
});
export var changeCatalogCategory = (_a = catalogSlice.actions, _a.changeCatalogCategory), saveFiltersData = _a.saveFiltersData, saveAvaildableFiltersData = _a.saveAvaildableFiltersData, saveLocalFilters = _a.saveLocalFilters, catalogFiltersHandler = _a.catalogFiltersHandler, clearAllCatalogFilters = _a.clearAllCatalogFilters, clearLocalFilters = _a.clearLocalFilters;
export default catalogSlice.reducer;
