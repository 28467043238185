var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./CustomPopover.scss";
import { createPortal } from "react-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
export var CustomPopover = function (_a) {
    var arrowIndent = _a.arrowIndent, _b = _a.backgroundColor, backgroundColor = _b === void 0 ? "white" : _b, children = _a.children, content = _a.content, _c = _a.displayDelayMS, displayDelayMS = _c === void 0 ? 0 : _c, getPopoverContainer = _a.getPopoverContainer, onOpenChange = _a.onOpenChange, open = _a.open, overlayClassName = _a.overlayClassName, placement = _a.placement, _d = _a.popoverMargin, popoverMargin = _d === void 0 ? 12 : _d, trigger = _a.trigger;
    var _e = __read(useState(false), 2), isPopoverShown = _e[0], setIsPopoverShown = _e[1];
    var _f = __read(useState(null), 2), popoverContainer = _f[0], setPopoverContainer = _f[1];
    var _g = __read(useState(placement || "top"), 2), place = _g[0], setPlace = _g[1];
    var childrenRef = useRef(null);
    var popoverRef = useRef(null);
    var arrowOffset = useRef(arrowIndent || 20);
    useEffect(function () {
        var popoverContainer = getPopoverContainer && getPopoverContainer();
        if (!popoverContainer)
            popoverContainer = childrenRef.current;
        setPopoverContainer(popoverContainer);
    }, []);
    useEffect(function () {
        setIsPopoverShown(!!open);
    }, [open]);
    useEffect(function () {
        var _a, _b;
        var popover = popoverRef.current;
        if (popover) {
            onOpenChange && onOpenChange(isPopoverShown);
            (_a = popoverRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty("--background-color", "".concat(backgroundColor));
            if (isPopoverShown) {
                (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && document.body.addEventListener("click", outsideClickHandler);
                window.addEventListener("resize", reziseHandler);
                (_b = childrenRef.current) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else {
                (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && document.body.removeEventListener("click", outsideClickHandler);
                window.removeEventListener("resize", reziseHandler);
            }
        }
    }, [isPopoverShown]);
    useEffect(function () {
        if (popoverRef.current) {
            validatePlacement();
            initStyles();
        }
    }, [popoverRef.current, place, isPopoverShown]);
    var initStyles = useCallback(function () {
        var popover = popoverRef.current;
        var popoverWidth = popover.offsetWidth, popoverHeight = popover.offsetHeight;
        var _a = __read(place.split(/(?=[B,T,L,R])/), 2), mainPosition = _a[0], secondaryPosition = _a[1];
        var isVertical = mainPosition === "top" || mainPosition === "bottom";
        var isHorizontal = mainPosition === "left" || mainPosition === "right";
        setCoordinates();
        setStyleVariables();
        function setCoordinates() {
            var coordinates = { left: "", right: "", top: "", bottom: "" };
            coordinates[mainPosition] = "-".concat((isVertical ? popoverHeight : popoverWidth) + popoverMargin, "px");
            if (secondaryPosition) {
                coordinates[secondaryPosition.toLowerCase()] = 0;
            }
            else {
                if (isVertical)
                    coordinates.left = "calc(50% - ".concat(popoverWidth / 2, "px)");
                if (isHorizontal)
                    coordinates.top = "calc(50% - ".concat(popoverHeight / 2, "px)");
            }
            Object.keys(coordinates).map(function (pos) { return (popover.style[pos] = coordinates[pos]); });
        }
        function setStyleVariables() {
            var _a, _b, _c, _d;
            var arrowIndent = arrowOffset.current;
            if (isHorizontal) {
                (_a = popoverRef.current) === null || _a === void 0 ? void 0 : _a.style.setProperty("--arrow-x", "".concat(mainPosition === "left" ? popoverWidth : 0, "px"));
                var arrowY = secondaryPosition
                    ? secondaryPosition === "Top"
                        ? arrowIndent
                        : popoverHeight - arrowIndent
                    : popoverHeight / 2;
                (_b = popoverRef.current) === null || _b === void 0 ? void 0 : _b.style.setProperty("--arrow-y", "".concat(arrowY, "px"));
            }
            else {
                (_c = popoverRef.current) === null || _c === void 0 ? void 0 : _c.style.setProperty("--arrow-y", "".concat(mainPosition === "top" ? popoverHeight : 0, "px"));
                var arrowX = secondaryPosition
                    ? secondaryPosition === "Left"
                        ? arrowIndent
                        : popoverWidth - arrowIndent
                    : popoverWidth / 2;
                (_d = popoverRef.current) === null || _d === void 0 ? void 0 : _d.style.setProperty("--arrow-x", "".concat(arrowX, "px"));
            }
        }
    }, [place]);
    var validatePlacement = useCallback(function () {
        var popover = popoverRef.current;
        var trigger = childrenRef.current;
        if (!popover || !trigger)
            return;
        popover.style.display = "block";
        var _a = __read(place.split(/(?=[B,T,L,R])/), 1), mainPosition = _a[0];
        var _b = childrenRef.current.getBoundingClientRect(), left = _b.left, right = _b.right, top = _b.top, bottom = _b.bottom;
        var triggerWidth = trigger.offsetWidth, triggerHeight = trigger.offsetHeight;
        var popoverWidth = popover.offsetWidth, popoverHeight = popover.offsetHeight;
        var windowWidth = window.innerWidth - (window.innerWidth - document.documentElement.clientWidth);
        var windowHeight = window.innerHeight;
        var isValidTop = top - popoverHeight - 12 > 0;
        var isValidBottom = bottom + popoverHeight + 12 < windowHeight;
        var isValidLeft = left - popoverWidth - 12 > 0;
        var isValidRight = right + popoverWidth + 12 < windowWidth;
        var adjustedMainPosition = validateMainPosition();
        var adjustedSecondaryPosition = adjustedMainPosition === mainPosition ? validateSecondaryPosition() : "";
        if (adjustedMainPosition + adjustedSecondaryPosition !== place) {
            setPlace((adjustedMainPosition + adjustedSecondaryPosition));
        }
        else {
            return;
        }
        function validateMainPosition() {
            if ((mainPosition === "top" && !isValidTop) ||
                (mainPosition === "bottom" && !isValidBottom) ||
                (mainPosition === "left" && !isValidLeft) ||
                (mainPosition === "right" && !isValidRight)) {
                if (isValidTop)
                    return "top";
                if (isValidBottom)
                    return "bottom";
                if (isValidLeft)
                    return "left";
                return "right";
            }
            else {
                if (placement) {
                    var _a = __read(placement.split(/(?=[B,T,L,R])/), 1), defaultMainPosition = _a[0];
                    if (isValidTop && defaultMainPosition === "top")
                        return "top";
                    if (isValidBottom && defaultMainPosition === "bottom")
                        return "bottom";
                    if (isValidLeft && defaultMainPosition === "left")
                        return "left";
                    if (isValidRight && defaultMainPosition === "right")
                        return "right";
                }
                return mainPosition;
            }
        }
        function validateSecondaryPosition() {
            if (adjustedMainPosition === "top" || adjustedMainPosition === "bottom") {
                if (right - triggerWidth / 2 + popoverWidth / 2 > windowWidth)
                    return "Right";
                if (left + triggerWidth / 2 - popoverWidth / 2 < 0)
                    return "Left";
            }
            else {
                if (top + triggerHeight / 2 - popoverHeight / 2 < 0)
                    return "Bottom";
                if (bottom - triggerHeight / 2 + popoverHeight / 2 > windowHeight)
                    return "Top";
            }
            return "";
        }
    }, [place]);
    var reziseHandler = useCallback(function () {
        validatePlacement();
    }, [place]);
    var outsideClickHandler = useCallback(function (e) {
        childrenRef.current && !childrenRef.current.contains(e.target) && setIsPopoverShown(false);
    }, []);
    var overlayHoverHandler = useCallback(function (e) {
        if (trigger === null || trigger === void 0 ? void 0 : trigger.includes("hover")) {
            var triggerNode_1 = e.currentTarget;
            setIsPopoverShown(true);
            var mouseLeaveHandler_1 = function () {
                var closeTimeout = setTimeout(function () {
                    setIsPopoverShown(false);
                    triggerNode_1.removeEventListener("mouseleave", mouseLeaveHandler_1);
                }, 100);
                var enterHandler = function () {
                    clearTimeout(closeTimeout);
                    triggerNode_1.removeEventListener("mouseenter", enterHandler);
                };
                triggerNode_1.addEventListener("mouseenter", enterHandler);
            };
            triggerNode_1.addEventListener("mouseleave", mouseLeaveHandler_1);
        }
    }, [trigger]);
    var overlayClickHandler = function (parentClickHandler) {
        return function (e) {
            parentClickHandler && parentClickHandler(e);
            (trigger === null || trigger === void 0 ? void 0 : trigger.includes("click")) && setIsPopoverShown(function (prev) { return !prev; });
        };
    };
    return (React.createElement(React.Fragment, null,
        popoverContainer
            ? createPortal(React.createElement("div", { className: "customPopover customPopover_".concat(place, " ") +
                    (isPopoverShown ? "customPopover_active " : "") +
                    (overlayClassName ? "".concat(overlayClassName) : ""), ref: popoverRef, style: { animationDelay: "".concat(displayDelayMS, "ms") } },
                React.createElement("div", { className: 'customPopover__arrow' }),
                React.createElement("div", { className: 'customPopover__contentWrapper' },
                    React.createElement("div", { className: 'customPopover__content' }, content))), popoverContainer)
            : null,
        children
            ? React.cloneElement(children, {
                onMouseEnter: overlayHoverHandler,
                onClick: overlayClickHandler(children.props.onClick),
                ref: childrenRef,
                className: "customPopoverTrigger ".concat(children.props.className || ""),
            })
            : null));
};
