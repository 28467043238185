import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    postsState: { year: "ПО ПОРЯДКУ", page: "1" },
};
export var postsSlice = createSlice({
    name: "posts",
    initialState: initialState,
    reducers: {
        savePostsSortAndPage: function (state, action) {
            state.postsState = action.payload;
        },
    },
});
export var savePostsSortAndPage = postsSlice.actions.savePostsSortAndPage;
export default postsSlice.reducer;
