var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "Style/classes/carouselControls-arrow.scss";
import "./GoodsCarousel.scss";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, FreeMode, Navigation } from "swiper/modules";
import { useAppSelector } from "Hooks/useAppSelector";
import { reformatPrice } from "Utils/reformatPrice";
import { GoodsCarouselFallback } from "./components/GoodsCarouselFallback";
import { CarouselNavArrow } from "Components/CarouselNavArrow/CarouselNavArrow";
import { FavouritesHeart } from "Components/FavouritesHeart/FavouritesHeart";
import { Label } from "Components/Label/Label";
import { ArrowIcon } from "Svg/ArrowIcon";
import { productClickGTM } from "Utils/analyticEvents";
export var GoodsCarousel = function (_a) {
    var _b = _a.catalogLink, catalogLink = _b === void 0 ? null : _b, category = _a.category, categoryOfGoodsData = _a.categoryOfGoodsData, className = _a.className, gtmList = _a.gtmList, intersectionObserverClassName = _a.intersectionObserverClassName, _c = _a.isLoading, isLoading = _c === void 0 ? false : _c;
    var _d = useAppSelector(function (state) { return state.app; }), deviceInfo = _d.deviceInfo, breakpointMatches = _d.breakpointMatches;
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var _e = __read(useState(null), 2), swiperRef = _e[0], setSwiperRef = _e[1];
    var _f = __read(useState("auto"), 2), numberOfSlides = _f[0], setNumberOfSlides = _f[1];
    var _g = __read(useState(16), 2), paddingOfSlides = _g[0], setPaddingOfSlides = _g[1];
    var _h = __read(useState(true), 2), isSlidesSticky = _h[0], setIsSlidesSticky = _h[1];
    useLayoutEffect(function () {
        breakpointMatches[1440] ? setNumberOfSlides(3) : setNumberOfSlides(4);
        breakpointMatches[960] && setNumberOfSlides("auto");
        if (breakpointMatches[600]) {
            setPaddingOfSlides(8);
            setIsSlidesSticky(false);
        }
        else {
            setPaddingOfSlides(16);
            setIsSlidesSticky(true);
        }
    }, [breakpointMatches]);
    useEffect(function () {
        swiperRef && swiperRef.slideReset(0);
    }, [numberOfSlides]);
    var getAltDescription = function (product) {
        var result = "\u041C\u043E\u0434\u0435\u043B\u044C: ".concat(product.model, "; \u0411\u0440\u0435\u043D\u0434: ").concat(product.brand.name, "; ");
        product.altData &&
            Object.keys(product.altData).forEach(function (name) {
                if (name === "materials") {
                    var value = product.altData[name].length ? product.altData[name].join(", ") : "Не указано";
                    result += "\u041C\u0430\u0442\u0435\u0440\u0438\u0430\u043B: ".concat(value, "; ");
                }
                else if (name === "color") {
                    result += "\u0426\u0432\u0435\u0442: ".concat(product.altData[name] || "Не указано", "; ");
                }
                else if (name === "country") {
                    result += "\u0421\u0442\u0440\u0430\u043D\u0430 \u043F\u0440\u043E\u0438\u0437\u0432\u043E\u0434\u0441\u0442\u0432\u0430: ".concat(product.altData[name] || "Не указано");
                }
            });
        return result;
    };
    var productPreviewClickHandler = function (products) {
        if ((!user || (user && user.role.id === "1")) && gtmList) {
            productClickGTM({
                list: gtmList,
                productsForSend: products,
                fullProductsList: categoryOfGoodsData,
            });
        }
    };
    return !isLoading && !!(categoryOfGoodsData === null || categoryOfGoodsData === void 0 ? void 0 : categoryOfGoodsData.length) ? (React.createElement("div", { className: "goodsCarousel goodsCarousel_".concat(category) +
            (intersectionObserverClassName ? " ".concat(intersectionObserverClassName) : "") +
            (className ? " ".concat(className) : "") },
        React.createElement(CarouselNavArrow, { className: "goodsCarousel__prevArrow goodsCarousel__prevArrow_".concat(category), type: 'prev' }),
        React.createElement(Swiper, { className: 'goodsCarousel__track', onSwiper: setSwiperRef, spaceBetween: paddingOfSlides, slidesPerView: numberOfSlides, keyboard: true, freeMode: {
                enabled: true,
                sticky: isSlidesSticky,
                momentumBounce: false,
            }, speed: deviceInfo.deviceType === "desktop" ? 300 : 100, threshold: deviceInfo.deviceType === "desktop" ? 0 : 3, navigation: {
                prevEl: ".goodsCarousel__prevArrow_".concat(category),
                nextEl: ".goodsCarousel__nextArrow_".concat(category),
            }, modules: [Keyboard, FreeMode, Navigation] },
            categoryOfGoodsData.map(function (product, idx) { return (React.createElement(SwiperSlide, { key: product.id, onClick: function () { return productPreviewClickHandler([product]); } },
                React.createElement("div", { className: 'goodsCarousel__track__slide' },
                    React.createElement("div", { className: 'goodsCarousel__track__slide__imgWrapper' },
                        React.createElement(FavouritesHeart, { className: 'goodsCarousel__track__slide__imgWrapper__favouritesIcon', category: category, product: product }),
                        React.createElement(NavLink, { "aria-label": "preview of - ".concat(product.model), itemScope: true, itemType: 'https://schema.org/ImageObject', to: "/products/".concat(product.url, "/"), target: '_blank', rel: 'noopener noreferrer', title: "".concat(product.model).concat(product.model_full ? " " + product.model_full : ""), onError: function (e) {
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                e.currentTarget.style.borderRadius = "12px";
                            } },
                            React.createElement("img", { className: 'skeletonLoading', itemProp: 'contentUrl', src: product.image, alt: getAltDescription(product), loading: idx > 3 ? (deviceInfo.browserName !== "Safari" ? "lazy" : "eager") : undefined, onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) {
                                    e.currentTarget.classList.remove("skeletonLoading");
                                    e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                } })),
                        product.promoLabel && (React.createElement(Label, { className: 'goodsCarousel__track__slide__imgWrapper__label', labelData: product.promoLabel }))),
                    React.createElement("div", { className: 'goodsCarousel__track__slide__productInfo' },
                        React.createElement("img", { className: 'skeletonLoading', src: product.brand.image, alt: "".concat(product.brand.name, " brand logo"), loading: idx > 3 ? (deviceInfo.browserName !== "Safari" ? "lazy" : "eager") : undefined, onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) {
                                e.currentTarget.classList.remove("skeletonLoading");
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                            } }),
                        React.createElement("span", { className: 'goodsCarousel__track__slide__productInfo__name typography_tec_16_default' }, product.model),
                        React.createElement("div", { className: 'goodsCarousel__track__slide__productInfo__price', itemScope: true, itemProp: 'offers', itemType: 'http://schema.org/Offer' },
                            React.createElement("p", { className: "goodsCarousel__track__slide__productInfo__price__actual" +
                                    (product.actual_price !== product.price
                                        ? " goodsCarousel__track__slide__productInfo__price__discount"
                                        : "") }, reformatPrice(product.actual_price)),
                            product.actual_price !== product.price && (React.createElement("p", { className: 'goodsCarousel__track__slide__productInfo__price__oldPrice typography_tec_16_default' }, reformatPrice(product.price)))))))); }),
            catalogLink && (React.createElement(SwiperSlide, null,
                React.createElement("div", { className: 'goodsCarousel__track__slide' },
                    React.createElement("div", { className: 'goodsCarousel__track__slide__imgWrapper' },
                        React.createElement(NavLink, { rel: 'noopener noreferrer', target: '_blank', to: catalogLink.url, onError: function (e) {
                                e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                e.currentTarget.style.borderRadius = "12px";
                            } },
                            React.createElement("img", { className: 'skeletonLoading', src: catalogLink.image, alt: "Link to - ".concat(category), loading: deviceInfo.browserName !== "Safari" ? "lazy" : "eager", onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) {
                                    e.currentTarget.classList.remove("skeletonLoading");
                                    e.currentTarget.style.backgroundColor = "var(--grey-100)";
                                } }))),
                    React.createElement("p", { className: 'goodsCarousel__track__slide__promoTxt typography_bodyTxt' }, "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0435\u0442\u044C \u0432\u0441\u0435 \u0442\u043E\u0432\u0430\u0440\u044B \u0434\u0430\u043D\u043D\u043E\u0439 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
                    React.createElement(NavLink, { className: 'goodsCarousel__track__slide__promoBtn customBtn customBtn_outline', rel: 'noopener noreferrer', target: '_blank', to: catalogLink.url },
                        "\u0412\u0441\u0435 \u043F\u0440\u0435\u0434\u043B\u043E\u0436\u0435\u043D\u0438\u044F",
                        React.createElement(ArrowIcon, null)))))),
        React.createElement(CarouselNavArrow, { className: "goodsCarousel__nextArrow goodsCarousel__nextArrow_".concat(category), type: 'next' }))) : isLoading ? (React.createElement(GoodsCarouselFallback, { category: category, intersectionObserverClassName: intersectionObserverClassName, withTitle: false })) : null;
};
