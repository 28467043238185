var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./ManagerWidget.scss";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAppSelector } from "Hooks/useAppSelector";
import { ChevronIcon } from "Svg/ChevronIcon";
import { AttachmentLinkIcon } from "Svg/AttachmentLinkIcon";
export var ManagerWidget = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var widgetRef = useRef();
    var _a = __read(useState(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' })), 2), activeIcon = _a[0], setActiveIcon = _a[1];
    var _b = __read(useState(location.pathname + location.search), 2), currentUrl = _b[0], setCurrentUrl = _b[1];
    useEffect(function () {
        var widget = document.querySelector(".managerWidget");
        if (widget && user && user.role.id !== "1") {
            widgetRef.current = widget;
            widget.classList.add("managerWidget_showed");
            setActiveIcon(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' }));
            checkUrl();
        }
        else if (widget) {
            widget.classList.remove("managerWidget_showed");
            widget.classList.remove("managerWidget_hidden");
        }
    }, [user]);
    useEffect(function () { return checkUrl(); }, [location]);
    var checkUrl = function () {
        var url = location.pathname + location.search;
        if (url !== currentUrl) {
            setCurrentUrl(url);
        }
        else {
            setTimeout(function () { return hideWidget(); }, 3000);
        }
    };
    var widgetHandler = function () {
        checkUrl();
        if (widgetRef.current.classList.contains("managerWidget_hidden")) {
            widgetRef.current.classList.remove("managerWidget_hidden");
            widgetRef.current.classList.add("managerWidget_showed");
            setActiveIcon(React.createElement(AttachmentLinkIcon, { className: 'managerWidget__icon' }));
        }
        else {
            navigate("/managerLinks/");
            hideWidget();
        }
    };
    var hideWidget = function (event) {
        var _a, _b;
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        (_a = widgetRef.current) === null || _a === void 0 ? void 0 : _a.classList.remove("managerWidget_showed");
        (_b = widgetRef.current) === null || _b === void 0 ? void 0 : _b.classList.add("managerWidget_hidden");
        setActiveIcon(React.createElement(ChevronIcon, { className: 'managerWidget__icon' }));
    };
    return (React.createElement("div", { className: 'managerWidget', onClick: widgetHandler },
        React.createElement("div", { className: 'managerWidget__content' }, activeIcon)));
};
