var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import "./LocationSelect.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeCity } from "Redux/slices/appSlice";
import { saveUserData } from "Redux/slices/userInfoSlice";
import { fetchApi } from "Utils/fetchApi";
import { CustomModal } from "Components/CustomModal/CustomModal";
import { CloseIcon } from "Components/CloseIcon/CloseIcon";
import { CitySelectionInput } from "Components/CitySelectionInput/CitySelectionInput";
import { SearchIcon } from "Svg/SearchIcon";
import { CheckIcon } from "Svg/CheckIcon";
import { Loader } from "Components/Loader/Loader";
export var LocationSelect = function (_a) {
    var _b = _a.isGlobalChange, isGlobalChange = _b === void 0 ? true : _b, onCancel = _a.onCancel, onChange = _a.onChange, userCity = _a.userCity, visible = _a.visible;
    var dispatch = useAppDispatch();
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var _c = __read(useState((userCity === null || userCity === void 0 ? void 0 : userCity.city_name) || ""), 2), searchedCityName = _c[0], setSearchedCityName = _c[1];
    var _d = __read(useState(userCity), 2), newCity = _d[0], setNewCity = _d[1];
    useEffect(function () {
        if (userCity && visible) {
            setNewCity(userCity);
            setSearchedCityName(userCity.city_name);
        }
    }, [userCity, visible]);
    var saveSelectedCity = function () {
        if (newCity && isGlobalChange) {
            dispatch(changeCity(newCity));
            updateCityInDB(newCity.city_id);
        }
        onChange && newCity && onChange(newCity);
        closeModalHandler();
    };
    var saveDefaultCity = function (city) {
        setNewCity(city);
        if (city.city_id && isGlobalChange) {
            dispatch(changeCity(city));
            updateCityInDB(city.city_id);
        }
        onChange && city && onChange(city);
        closeModalHandler();
    };
    var closeModalHandler = function () {
        onCancel();
        setNewCity(null);
    };
    var updateCityInDB = function (cityId) {
        user &&
            (userCity === null || userCity === void 0 ? void 0 : userCity.city_id) &&
            userCity.city_id.toString() !== cityId.toString() &&
            fetchApi({
                method: "POST",
                url: "/user/update",
                body: {
                    city_id: cityId,
                },
            }).then(function (_a) {
                var data = _a.data;
                return dispatch(saveUserData(data));
            });
    };
    var _e = __read(useState([]), 2), defaultCities = _e[0], setDefaultCities = _e[1];
    useEffect(function () {
        visible &&
            defaultCities.length === 0 &&
            fetchApi({ method: "GET", url: "/cities/main" }).then(function (_a) {
                var data = _a.data;
                return setDefaultCities(data);
            });
    }, [visible]);
    return (React.createElement(CustomModal, { className: 'locationSelect', fullscreen: false, open: visible, onClose: closeModalHandler },
        React.createElement("div", { className: 'customModal__content__header' },
            React.createElement(CloseIcon, { className: 'customModal__content__header__closeIcon', onClick: closeModalHandler }),
            React.createElement("span", { className: 'typography_h1' }, "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0433\u043E\u0440\u043E\u0434")),
        React.createElement("div", { className: 'customModal__content__body' },
            React.createElement(CitySelectionInput, { onBlurHandler: saveSelectedCity, placeholder: '\u0412\u0430\u0448 \u0433\u043E\u0440\u043E\u0434', searchedCityName: searchedCityName, setNewCity: setNewCity, setSearchedCityName: setSearchedCityName, suffixIcon: React.createElement(SearchIcon, null), userCity: userCity }),
            defaultCities.length > 0 ? (React.createElement("ul", { className: 'locationSelect__links' }, defaultCities.map(function (defaultCity) { return (React.createElement("li", { className: 'locationSelect__links__item typography_tec_16_default', key: defaultCity.city_id, onClick: function () { return saveDefaultCity(defaultCity); } },
                React.createElement("span", null, defaultCity.city_name),
                (newCity === null || newCity === void 0 ? void 0 : newCity.city_id) === (defaultCity === null || defaultCity === void 0 ? void 0 : defaultCity.city_id) && React.createElement(CheckIcon, null))); }))) : (React.createElement(Loader, { className: 'locationSelect__defaultCitiesLoader' })))));
};
