var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { changeAuthModalVisibility, changeSnackbarData } from "Redux/slices/appSlice";
export var AuthWrapper = function (_a) {
    var children = _a.children;
    var dispatch = useAppDispatch();
    var navigate = useNavigate();
    var isAuthModalShown = useAppSelector(function (state) { return state.app; }).isAuthModalShown;
    var user = useAppSelector(function (state) { return state.userInfo; }).user;
    var _b = __read(useState(false), 2), authModalDisplayShown = _b[0], setAuthModalDisplayShown = _b[1];
    useEffect(function () {
        return function () { return setAuthModalDisplayShown(false); };
    }, []);
    useEffect(function () {
        if (!user && authModalDisplayShown) {
            navigate("/");
        }
        else if (!user && !isAuthModalShown) {
            setAuthModalDisplayShown(true);
            dispatch(changeAuthModalVisibility(true));
            dispatch(changeSnackbarData({ message: "Страница только  для авторизованных пользователей", status: "error" }));
            window.scrollTo(0, 0);
        }
    }, [user, isAuthModalShown, location]);
    return user ? children : null;
};
