var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    isUserTypeDefined: false,
    token: "",
    user: null,
};
export var userInfoSlice = createSlice({
    name: "userInfo",
    initialState: initialState,
    reducers: {
        saveUserData: function (state, action) {
            state.user = action.payload;
        },
        saveAuthToken: function (state, action) {
            !action.payload ? localStorage.removeItem("token") : localStorage.setItem("token", action.payload);
            state.token = action.payload;
        },
        changeUserTypeDefined: function (state, action) {
            state.isUserTypeDefined = action.payload;
        },
    },
});
export var saveUserData = (_a = userInfoSlice.actions, _a.saveUserData), saveAuthToken = _a.saveAuthToken, changeUserTypeDefined = _a.changeUserTypeDefined;
export default userInfoSlice.reducer;
