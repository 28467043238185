import "./BrandsGrid.scss";
import React from "react";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "Hooks/useAppSelector";
export var BrandsGrid = function (_a) {
    var className = _a.className, brandsData = _a.brandsData, _b = _a.isLoading, isLoading = _b === void 0 ? false : _b;
    var isFlexGapSupported = useAppSelector(function (state) { return state.app; }).isFlexGapSupported;
    var skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    return (React.createElement("div", { className: "brandsGrid" + (className ? " ".concat(className) : ""), "data-is_flex_gap_supported": isFlexGapSupported }, isLoading
        ? skeletons.map(function (skeleton) { return React.createElement("div", { className: 'brandsGrid__item_skeleton skeletonLoading', key: skeleton }); })
        : brandsData.map(function (brand) { return (React.createElement(NavLink, { className: 'brandsGrid__item', to: "/brands/".concat(brand.url, "/"), "aria-label": "".concat(brand.name, " - brand logo"), key: brand.id },
            React.createElement("img", { className: 'brandsGrid__item__logo skeletonLoading', src: brand.image, alt: "".concat(brand.name, " - brand logo"), loading: 'lazy', onLoad: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); }, onError: function (e) { return e.currentTarget.classList.remove("skeletonLoading"); } }))); })));
};
