import "./Snackbar.scss";
import React, { useEffect } from "react";
import { useAppDispatch } from "Hooks/useAppDispatch";
import { useAppSelector } from "Hooks/useAppSelector";
import { changeSnackbarData } from "Redux/slices/appSlice";
import { SuccessIcon_monochrome } from "Svg/SuccessIcon_monochrome";
import { AttentionIcon_color } from "Svg/AttentionIcon_color";
export var Snackbar = function (_a) {
    var className = _a.className;
    var dispatch = useAppDispatch();
    var snackbarData = useAppSelector(function (state) { return state.app; }).snackbarData;
    useEffect(function () {
        var timer;
        if (snackbarData) {
            timer = setTimeout(function () {
                dispatch(changeSnackbarData(null));
            }, 4000);
        }
        return function () {
            timer && clearTimeout(timer);
        };
    }, [snackbarData]);
    return (React.createElement("div", { className: "snackbarNotification" +
            (snackbarData ? " snackbarNotification_visible" : "") +
            (className ? " ".concat(className) : "") },
        React.createElement("div", { className: 'snackbarNotification__icon' }, (snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.status) === "success" ? (React.createElement(SuccessIcon_monochrome, null)) : (React.createElement(AttentionIcon_color, { backgroundFill: 'var(--red-500)' }))),
        React.createElement("span", { className: 'snackbarNotification__msg typography_tec_buttonM' }, snackbarData === null || snackbarData === void 0 ? void 0 : snackbarData.message)));
};
