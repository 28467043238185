export var genderList = [
    { gender: "все", genderNumber: "0" },
    { gender: "мужское", genderNumber: "1" },
    { gender: "женское", genderNumber: "2" },
];
export var metaDescription = "| Фирменная люксовая одежда из Италии с доставкой и примеркой по выгодным ценам в бутике LS.NET.RU. Сделайте заказ на сайте или звоните по телефону +7 495 191-26-04.";
export var metaKeywords = "LS.NET.RU, люксовая одежда, итальянские бренды, бутик, заказ";
export var metaTitle_end = "| Фирменный бутик LS.NET.RU";
export var defaultGenderMetaDescription = {
    0: "Продажа одежды итальянских эксклюзивных брендов в магазине LS.NET.RU. Широкий выбор люксовых марок одежды из Италии, цены на сайте. Доставка элитных вещей в Москве и по России.",
    1: "Приобрести одежду для мужчин из Италии от интернет-магазина эксклюзивных вещей LS.NET.RU. Доставка элитной итальянской мужской одежды в Москве и по России. Для заказа оставьте заявку на сайте.",
    2: "Продажа женской люксовой одежды итальянских брендов от интернет-магазина эксклюзивных вещей LS.NET.RU. Доставка женской элитной одежды высокого качества из Италии в Москве и по России.",
};
export var defaultGenderMetaTitle = {
    0: "Купить итальянскую брендовую одежду в Москве | Цены на элитные бренды в интернет-магазине LS.NET.RU",
    1: "Купить итальянскую брендовую одежду для мужчин в Москве | Цены на элитные бренды в интернет-магазине LS.NET.RU",
    2: "Купить итальянскую брендовую одежду для женщин в Москве | Цены на элитные бренды в интернет-магазине LS.NET.RU",
};
