var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import { getUniqueAndConcatedLocalData } from "Utils/getUniqueAndConcatedLocalData";
import localStorage from "Utils/localStorage";
import { fetchApi } from "Utils/fetchApi";
var localCart = localStorage.getItem("cart") || "[]";
if (localCart.indexOf("wishlist_count") > -1 || localCart.indexOf("items") > -1) {
    localCart = "[]";
    localStorage.removeItem("cart");
}
var uniqCart = getUniqueAndConcatedLocalData(JSON.parse(localCart));
localCart = uniqCart;
localStorage.setItem("cart", JSON.stringify(uniqCart));
var localFavourites = JSON.parse(localStorage.getItem("favourites") || "[]");
var uniqFavourites = getUniqueAndConcatedLocalData(localFavourites);
localFavourites = uniqFavourites;
localStorage.setItem("favourites", JSON.stringify(uniqFavourites));
var initialState = {
    productsInCart: localCart,
    productsInFavourites: localFavourites,
    showFavouritesIndicator: localFavourites.length > 0 && localStorage.getItem("showFavouritesIndicator") === "true",
};
export var cartSlice = createSlice({
    name: "cart",
    initialState: initialState,
    reducers: {
        addProductToCartOrFavourites: function (state, action) {
            var _a = action.payload, location = _a.location, newProducts = _a.newProducts, needSyncWithDB = _a.needSyncWithDB, isSoftAdding = _a.isSoftAdding, skipFavouritesIndicator = _a.skipFavouritesIndicator;
            var localProducts = localStorage.getItem(location);
            var currentProducts = JSON.parse(localProducts || "[]");
            var checkProductIds = newProducts.map(function (product) { return product.productId; });
            var result = [];
            if (isSoftAdding) {
                var updatedNewProducts = __spreadArray([], __read(newProducts), false).map(function (product) { return ({
                    productId: product.productId,
                    sizes: __spreadArray([], __read(product.sizes), false).sort(function (a, b) { return (a > b ? 1 : -1); }),
                }); });
                updatedNewProducts = newProducts.map(function (newProduct) {
                    var unicSizes = new Set(newProduct.sizes);
                    var currentProduct = currentProducts.filter(function (current) { return current.productId === newProduct.productId; })[0];
                    currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.sizes.forEach(function (size) { return unicSizes.add(size); });
                    var sizes = Array.from(unicSizes).sort(function (a, b) { return (a > b ? 1 : -1); });
                    return { productId: newProduct.productId, sizes: sizes };
                });
                var filterredProducts = currentProducts.filter(function (product) { return !checkProductIds.includes(product.productId); });
                result = __spreadArray(__spreadArray([], __read(updatedNewProducts), false), __read(filterredProducts), false);
            }
            else {
                var hardResult_1 = __spreadArray([], __read(currentProducts), false);
                newProducts.forEach(function (newProduct) {
                    var idx = currentProducts.findIndex(function (currentProduct) { return currentProduct.productId === newProduct.productId; });
                    if (idx >= 0) {
                        hardResult_1.splice(idx, 1, newProduct);
                    }
                    else {
                        hardResult_1 = __spreadArray(__spreadArray([], __read(hardResult_1), false), [newProduct], false);
                    }
                    result = hardResult_1;
                });
            }
            if (location === "cart") {
                state.productsInCart = result;
            }
            else {
                if (result.length > 0 && !skipFavouritesIndicator) {
                    localStorage.setItem("showFavouritesIndicator", "true");
                    state.showFavouritesIndicator = true;
                }
                state.productsInFavourites = result;
            }
            localStorage.setItem(location, JSON.stringify(result));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: "/".concat(location === "cart" ? "cart" : "wishlist", "/save"),
                    body: {
                        items: result,
                        saveHard: true,
                    },
                });
        },
        removeProductFromCartOrFavourites: function (state, action) {
            var _a = action.payload, location = _a.location, needSyncWithDB = _a.needSyncWithDB, removedProducts = _a.removedProducts;
            var currentProducts = JSON.parse(localStorage.getItem(location) || "[]");
            var filterredProducts = currentProducts.filter(function (product) { return !removedProducts.includes(product.productId); });
            if (location === "cart") {
                state.productsInCart = filterredProducts;
            }
            else {
                state.productsInFavourites = filterredProducts;
                if (filterredProducts.length === 0) {
                    localStorage.removeItem("showFavouritesIndicator");
                    state.showFavouritesIndicator = false;
                }
            }
            localStorage.setItem(location, JSON.stringify(filterredProducts));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: "/".concat(location === "cart" ? "cart" : "wishlist", "/remove"),
                    body: { productIds: removedProducts },
                });
        },
        removeProductsFromLocalCartOrFavourites: function (state, action) {
            if (action.payload.includes("cart")) {
                localStorage.removeItem("cart");
                localStorage.removeItem("reserveOfAvailable");
                state.productsInCart = [];
            }
            if (action.payload.includes("favourites")) {
                localStorage.removeItem("favourites");
                localStorage.removeItem("showFavouritesIndicator");
                state.productsInFavourites = [];
                state.showFavouritesIndicator = false;
            }
        },
        rewriteCartOrFavourites: function (state, action) {
            var _a = action.payload, location = _a.location, needSyncWithDB = _a.needSyncWithDB, newProducts = _a.newProducts;
            if (location === "cart") {
                state.productsInCart = newProducts;
            }
            else {
                state.productsInFavourites = newProducts;
                if (newProducts.length === 0) {
                    localStorage.removeItem("showFavouritesIndicator");
                    state.showFavouritesIndicator = false;
                }
            }
            localStorage.setItem(location, JSON.stringify(newProducts));
            needSyncWithDB &&
                fetchApi({
                    method: "POST",
                    url: "/".concat(location === "cart" ? "cart" : "wishlist", "/save"),
                    body: {
                        items: newProducts,
                        saveHard: true,
                    },
                });
        },
        changeStatusOfFavouritesIndicator: function (state, action) {
            action.payload
                ? localStorage.setItem("showFavouritesIndicator", "true")
                : localStorage.removeItem("showFavouritesIndicator");
            state.showFavouritesIndicator = action.payload;
        },
    },
});
export var addProductToCartOrFavourites = (_a = cartSlice.actions, _a.addProductToCartOrFavourites), changeStatusOfFavouritesIndicator = _a.changeStatusOfFavouritesIndicator, removeProductFromCartOrFavourites = _a.removeProductFromCartOrFavourites, removeProductsFromLocalCartOrFavourites = _a.removeProductsFromLocalCartOrFavourites, rewriteCartOrFavourites = _a.rewriteCartOrFavourites;
export default cartSlice.reducer;
