import "./CatalogProductCardSkeleton.scss";
import React from "react";
export var CatalogProductCardSkeleton = function () {
    return (React.createElement("div", { className: 'catalogProductCardSkeleton' },
        React.createElement("div", { className: 'catalogProductCardSkeleton__preview skeletonLoading' }),
        React.createElement("div", { className: 'catalogProductCardSkeleton__title skeletonLoading' }),
        React.createElement("div", { className: 'catalogProductCardSkeleton__price' },
            React.createElement("div", { className: 'catalogProductCardSkeleton__price__item skeletonLoading' }),
            React.createElement("div", { className: 'catalogProductCardSkeleton__price__item skeletonLoading' }))));
};
