var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useRef, useState } from "react";
export var IntersectionObserverWrapper = function (_a) {
    var querySelector = _a.querySelector, observerCallback = _a.observerCallback, _b = _a.wasOnScreen, wasOnScreen = _b === void 0 ? false : _b, children = _a.children, _c = _a.options, options = _c === void 0 ? {} : _c;
    var wrapperRef = useRef(null);
    var mutationObserverRef = useRef(null);
    var intersectionObserverRef = useRef(null);
    var _d = __read(useState(wasOnScreen), 2), isComponentWasOnScreen = _d[0], setIsComponentWasOnScreen = _d[1];
    var _e = __read(useState(null), 2), observableEl = _e[0], setObservableEl = _e[1];
    useEffect(function () {
        var DOMEl = document.querySelector(querySelector);
        if (DOMEl) {
            setObservableEl(DOMEl);
        }
        else {
            mutationObserverRef.current = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    var _a;
                    var target = mutation.target;
                    if ((target === null || target === void 0 ? void 0 : target.classList.contains(querySelector.substring(1))) ||
                        ((_a = target === null || target === void 0 ? void 0 : target.firstChild) === null || _a === void 0 ? void 0 : _a.classList.contains(querySelector.substring(1)))) {
                        setObservableEl(document.querySelector(querySelector));
                    }
                });
            });
            mutationObserverRef.current.observe(wrapperRef.current, {
                childList: true,
            });
        }
        return function () {
            mutationObserverRef.current && mutationObserverRef.current.disconnect();
        };
    }, []);
    useEffect(function () {
        intersectionObserverRef.current = new IntersectionObserver(intersectionHandler, options);
        observableEl && intersectionObserverRef.current && intersectionObserverRef.current.observe(observableEl);
        return function () {
            observableEl && intersectionObserverRef.current && intersectionObserverRef.current.unobserve(observableEl);
        };
    }, [observableEl]);
    useEffect(function () {
        isComponentWasOnScreen && observerCallback();
    }, [isComponentWasOnScreen]);
    var intersectionHandler = function (entries) {
        entries.forEach(function (entry) { return entry.isIntersecting && !isComponentWasOnScreen && setIsComponentWasOnScreen(true); });
    };
    return React.createElement("div", { ref: wrapperRef }, children);
};
